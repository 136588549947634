<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Kontragent registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex flex-wrap">
            <div class="col-4 pl-0">
              <v-text-field
                :error-messages="usernameErrors"
                v-model="username"
                label="Username"
                outlined
                required
                dense
                @input="$v.username.$touch()"
                @blur="$v.username.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4">
              <v-text-field
                type="password"
                :error-messages="passErrors"
                v-model="password"
                label="Parol"
                outlined
                required
                dense
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4 pr-0">
              <v-text-field
                :error-messages="rePassErrors"
                type="password"
                v-model="rePassword"
                label="Parolni tasdiqlang"
                outlined
                dense
                required
                @input="$v.rePassword.$touch()"
                @blur="$v.rePassword.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4 pl-0">
              <v-text-field
                :error-messages="nameErrors"
                v-model="name"
                label="Ismi"
                outlined
                dense
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4">
              <v-text-field
                :error-messages="innErrors"
                v-mask="'#########'"
                v-model="inn"
                label="Inn"
                dense
                counter="9"
                outlined
                @input="$v.inn.$touch()"
                @blur="$v.inn.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4 pr-0">
              <v-text-field
                :error-messages="phoneErrors"
                v-mask="'### (##) ### ## ##'"
                v-model="phone"
                label="Telefon"
                outlined
                dense
                @input="$v.phone.$touch()"
                @blur="$v.phone.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4 pl-0">
              <v-text-field
                :error-messages="addressErrors"
                v-model="address"
                label="Yuridik manzil"
                outlined
                dense
                @input="$v.address.$touch()"
                @blur="$v.address.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4">
              <!-- <v-text-field
                v-model="bank"
                label="Bank"
                outlined
                dense
              ></v-text-field> -->
              <!-- <el-select
                placeholder="Bank"
                clearable
                filterable
                v-model="bank"
                style="width: 100%"
              >
                <el-option
                  v-for="b in $store.state.requests.allMfoBank"
                  :key="b.id"
                  :label="b.full_name"
                  :value="b.id"
                ></el-option>
              </el-select> -->

              <v-autocomplete
                :error-messages="mfoClient"
                v-model="bank"
                :items="getMfo"
                dense
                item-text="full_name"
                item-value="code"
                outlined
                label="Mfo"
                @input="$v.bank.$touch()"
                @blur="$v.bank.$touch()"
                @keypress="isNumberKey"
                v-debounce:200ms="getMfoDebounce"
              ></v-autocomplete>
            </div>
            <div class="col-4">
              <v-text-field
                type="number"
                v-model="bank_account"
                v-mask="'#### #### #### #### ####'"
                label="Hisob raqami"
                outlined
                dense
              ></v-text-field>
            </div>
          </div>

          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <v-btn
                :disabled="newKontragentloading"
                color="success"
                class="px-9 py-4"
                @click="save"
              >
                <i v-if="newKontragentloading" class="el-icon-loading"></i>
                Saqlash
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import debounce from "debounce";
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { isNumberKey } from '@/utils'

export default {
  data() {
    return {
      newKontragentloading: false,
      combobox: '',
      name: '',
      inn: '',
      phone: '',
      address: '',
      rePassword: '',
      password: '',
      username: '',
      bank: '',
      bank_account: ''
    }
  },
  validations: {
    username: {
      required
    },
    password: {
      required,
      minLength: minLength(8)
    },
    rePassword: {
      sameAsPassword: sameAs('password')
    },
    phone: {
      required
    },
    address: {
      required
    },
    inn: {
      required
    },
    name: {
      required
    },
    bank: {
      required
    }
  },
  computed: {
    getMfo() {
      return this.$store.state.requests.allMfoBank
    },
    mfoClient() {
      const errors = []
      if (!this.$v.bank.$dirty) return errors
      if (!this.$v.bank.required) {
        errors.push("To'ldirilishi shart bo'lgan qator")
      }
      return errors
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    usernameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors

      !this.$v.username.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    passErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength &&
        errors.push("Password 8 tadan ko'p bo'lishi kerak characters long")
      !this.$v.password.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    rePassErrors() {
      const errors = []
      if (!this.$v.rePassword.$dirty) return errors
      !this.$v.rePassword.sameAsPassword &&
        errors.push('Parol most bolishi kerak')
      return errors
    },
    innErrors() {
      const errors = []
      if (!this.$v.inn.$dirty) return errors

      !this.$v.inn.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    addressErrors() {
      const errors = []
      if (!this.$v.address.$dirty) return errors

      !this.$v.address.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.phone.$dirty) return errors

      !this.$v.phone.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors

      !this.$v.name.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kontragent registratsiyasi' }
    ])
  },
  watch: {
    bank_account: function (val) {
      this.bank_account = val.substring(0, 24)
    },
    username: function (val) {
      this.username = val.toLowerCase()
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.username))
        this.username = this.username.replace(/\s/g, '')
    }
  },
  methods: {
    isNumberKey,

    ...mapActions(['getMfoBankByNumber']),

    getMfoDebounce(mfoCode) {
      this.getMfoBankByNumber(mfoCode)
    },

    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          full_name: this.name,
          inn: this.inn,
          phone: this.phone,
          bank: this.bank,
          bank_account: this.bank_account,
          juridik_adress: this.address,
          user_info: {
            username: this.username,
            password: this.password
          },
          user_groups: [7]
        }
        this.newKontragentloading = true
        this.$store
          .dispatch('createKontragent', data)
          .then(() => {
            this.newKontragentloading = false
            this.name = ''
            this.inn = ''
            this.phone = ''
            ;(this.bank_account = ''), (this.bank = ''), (this.address = '')
            this.username = ''
            this.password = ''
            this.rePassword = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.newKontragentloading = false
            console.error(err)
          })
      }
    }
  }
}
</script>
